@import "~reflex-grid";

@font-face {
    font-family: 'Gotham Pro';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/gotham-pro/regular.eot');
    src: url('./assets/gotham-pro/regular.eot?#iefix') format('embedded-opentype'), url('./assets/gotham-pro/regular.woff2') format('woff2'), url('./assets/gotham-pro/regular.woff') format('woff'), url('./assets/gotham-pro/regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Gotham Pro';
    font-style: normal;
    font-weight: 500;
    src: url('./assets/gotham-pro/medium.eot');
    src: url('./assets/gotham-pro/medium.eot?#iefix') format('embedded-opentype'), url('./assets/gotham-pro/medium.woff2') format('woff2'), url('./assets/gotham-pro/medium.woff') format('woff'), url('./assets/gotham-pro/medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Gotham Pro';
    font-style: normal;
    font-weight: 700;
    src: url('./assets/gotham-pro/bold.eot');
    src: url('./assets/gotham-pro/bold.eot?#iefix') format('embedded-opentype'), url('./assets/gotham-pro/bold.woff2') format('woff2'), url('./assets/gotham-pro/bold.woff') format('woff'), url('./assets/gotham-pro/bold.ttf') format('truetype');
}

body {
    background: #1c1c1c;
    color: #fff;
    font-size: 16px;
    line-height: 1.8;
    margin: 0;
    font-family: 'Gotham Pro', -apple-system, BlinkMacSystemFont, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    transition: all .5s;
}

h2 {
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
}

h3 {
    font-size: 32px;
    font-weight: 700;
    text-transform: uppercase;
}

img {
    height: auto;
    max-width: 100%;
}

.section {
    padding-bottom: 30px;
    padding-top: 30px;
}

.container {
    min-width: 320px;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1200px !important;
    }
}
